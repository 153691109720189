import React from "react"

const ApartmentList = ({ title, apartments, legend }) => {

    const available = apartments.filter(item => item.status === 'available'),
          availableCount = available.length;

    return (
        <section className="apartment-list">
            <div className="container">
                <div className="row">
                    <div className="col-12 apartment-list__title-holder">
                        <h2 className="title-underline apartment-list__title">
                            { title }
                        </h2>
                        <div className="apartment-list__counter">
                            <h4>
                                Dostępne: <span className="color--main">{ availableCount }</span>/{ apartments.length }
                            </h4>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="apartment-list__table-holder">    
                            <div className="apartment-list__table mb-40">
                                <div className="table__row table__row--header">
                                    <div className="row__item">
                                        Mieszkanie
                                    </div>
                                    <div className="row__item">
                                        Piętro
                                    </div>
                                    <div className="row__item">
                                        Liczba pokoi
                                    </div>
                                    <div className="row__item">
                                        Wysokość
                                    </div>
                                    <div className="row__item">
                                        Metraż
                                    </div>
                                    <div className="row__item">
                                        Balkon/loggia/ogródek
                                    </div>
                                    <div className="row__item">
                                        Cena za m<sup>2</sup>
                                    </div>
                                    <div className="row__item">
                                        Cena całościowa
                                    </div>
                                    <div className="row__item">
                                        Status
                                    </div>
                                    <div className="row__item">
                                        Więcej
                                    </div>
                                </div>
                                {
                                    apartments.map((item, index) => {
                                        let status = '';

                                        if(item.status === 'available') {
                                            status = "Dostępne";
                                        } else if (item.status === 'reserved') {
                                            status = "Rezerwacja";
                                        } else if (item.status === 'unavailable') {
                                            status = "Niedostępne";
                                        }

                                        const area = item.area !== '-' ? `${item.area} m<sup>2</sup>` : item.area;

                                        return (
                                            <div className="table__row" key={ index }>
                                                <div className="row__item"> <div className="item__title">Mieszkanie: </div> <div dangerouslySetInnerHTML={{ __html: item.apartment_name }}></div></div>
                                                <div className="row__item">
                                                    <div className="item__title">Piętro: </div>
                                                    <div>
                                                        { item.floor }
                                                    </div>
                                                </div>
                                                <div className="row__item">
                                                    <div className="item__title">Liczba pokoi: </div>
                                                    <div>
                                                        { item.rooms }
                                                    </div>
                                                </div>
                                                <div className="row__item"><div className="item__title">Wysokość: </div> <div dangerouslySetInnerHTML={{ __html: item.height }}></div></div>
                                                <div className="row__item"><div className="item__title">Metraż: </div> <div dangerouslySetInnerHTML={{ __html: area }}></div></div>
                                                <div className="row__item"><div className="item__title">Balkon/loggia/ogródek: </div> <div dangerouslySetInnerHTML={{ __html: item.balcony }}></div></div>
                                                <div className="row__item">
                                                    <div className="item__title">Cena za m<sup>2</sup>: </div>
                                                    { !(item.status === 'unavailable') && (
                                                        <div>
                                                            { item.price_for_m }
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="row__item">
                                                    <div className="item__title">Cena całościowa: </div>
                                                    { !(item.status === 'unavailable') && (
                                                        <div>
                                                            { item.whole_price }
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={`row__item ${ item.status }`}>
                                                    <div className="item__title">Status: </div>
                                                    <div>
                                                        { status }
                                                    </div>
                                                </div>
                                                <div className="row__item">
                                                    { !(item.status === 'unavailable') && (
                                                        <a href={ item.file.source_url } target="_blank" rel="noreferrer" className="item__link">
                                                            <img src={require('../assets/img/house.png')} alt="Więcej" className="link__icon"/>
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <p className="apartment-list__legend" dangerouslySetInnerHTML={{ __html: legend }}></p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ApartmentList
