import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SmallBanner from "../components/smallBanner"
import ApartmentSearch from "../components/apartmentSearch"
import ApartmentList from "../components/apartmentList"
import ImageWithPdf from "../components/ImageWithPdf"

const Apartments = ({ location }) => {
	const AcfQuery = useStaticQuery(
		graphql`
			query ApartmentsQuery {
				allWordpressPage(filter: {title: {eq: "Mieszkania"}}) {
					edges {
						node {
							acf {
								banner_title_first_part
								banner_title_second_part
								banner_image {
									localFile{
										url
									}
								}
								apartmets_title
								apartments_list {
									apartment_name
									floor
									rooms
									height
									area
									balcony
									price_for_m
									whole_price
									status
									file {
										source_url
									}
								}
								apartments_legend
								aditionals {
									title
									image {
										localFile {
											url
										}
									}
									file {
										link
									}
								}
							}
						}
					}
				}
			}
		`
	)

	const isBrowser = typeof window !== "undefined";
	const data = AcfQuery.allWordpressPage.edges[0].node.acf;
	const apartments = AcfQuery.allWordpressPage.edges[0].node.acf.apartments_list;
	let filteredApartments = apartments;		  

	const apartmentsFiletering = (areaStart, areaEnd, floorStart, floorEnd) => {
		filteredApartments = apartments.filter(item => {
			const flatArea = parseFloat(item.area.replace(',', "."));
			if (flatArea >= areaStart && flatArea <= areaEnd && item.floor >= floorStart && item.floor <= floorEnd) {
				return item
			}
			return false;
		});
	}	
	if(isBrowser) { 
		const param = location.state;
		if (location.state.fromSearch) {
			apartmentsFiletering(param.areaStart, param.areaEnd, param.floorStart, param.floorEnd);
		} else {
			filteredApartments = apartments;
		}
	}

	// console.log('location: ', state);
	// if( !state ){
	// 	if (state.areaEnd) {
	// 		apartmentsFiletering(state.areaStart, state.areaEnd, state.floorStart, state.floorEnd);
	// 	}
	// }

	// if (location.hasOwnProperty('state')) {
	// 	if (location.state.hasOwnProperty('areaStart')) {
	// 		const param = location.state;
	// 		apartmentsFiletering(param.areaStart, param.areaEnd, param.floorStart, param.floorEnd);
	// 	}
	// }

	return (
		<Layout>
			<SEO title="Mieszkania" />
			<SmallBanner 
				image={ data.banner_image.localFile.url } 
				// image={ require('../assets/img/ApartmentsBanner.jpg') }
				titleFirstPart={ data.banner_title_first_part } 
				titleSecondPart={ data.banner_title_second_part } 
			/>
			<ApartmentSearch />
			<ApartmentList 
				title={ data.apartmets_title }
				apartments={ filteredApartments } 
				legend={ data.apartments_legend }
			/>
			{
				data.aditionals.map((item, index) => {

					if (index === 1) {
						return <ImageWithPdf
						key={ index }
						image={ item.image.localFile.url }
						// image={ require('../assets/img/garage.png') }
						title={ item.title }
						file={ item.file.link }
					/>
					} else {
						return <ImageWithPdf
							key={ index }
							image={ item.image.localFile.url }
							// image={ require('../assets/img/groundFloorGarage.png') }
							title={ item.title }
							file={ item.file.link}
						/>
					}
					
					})
			}
		</Layout>
	)
}

export default Apartments