import React from "react"

const ImageWithPdf = ({ image, title, file }) => (
	<section className="image-with-pdf">
        <div className="container">
            <div className="row image-with-pdf__holder">
                <div className="col-12 col-lg-6">
                    <img src={ image } alt=""/>
                </div>
                <div className="col-12 col-lg-4 offset-lg-2 image-with-pdf__content">
                    <h2 className="mb-30">
                        { title }
                    </h2>
                    <a href={ file } download className="btn">
                        Zobacz PDF
                    </a>
                </div>
            </div>
        </div>
    </section>
)

export default ImageWithPdf
